/** @format */
import './index.scss';
import TopHeader from '../../../components/layouts/TopHeader';
import ServiceAlertDropDownPage from './ServiceAlertDropDownPage';
import Filter from '../../../components/ui/Filter';
import '../../User/List/index.scss';
import { ChangeEvent } from 'react';

interface ServiceAlertHeaderProps {
    alertType: string;
    placeHolder: string;
    alertDescription: string;
    alertDescription2?: string;
    onCloseHandler: (event: any) => void;
    children?: any;
    closeFlag: any;
    searchText?: any;
    handleSearch?: (event: ChangeEvent<HTMLInputElement>) => any;
    isFiltered: boolean;
    filter: any;
    onKeyDown?: any;
    isBadgeEnabled?: boolean;
    isFilterIcon?: boolean;
    isDownloadIcon?: boolean;
    isCreateAdvisory?: boolean;
    handleOnClickDownload?: any;
    setMakeAPICall?: any;
}

export const ServiceAlertHeader: React.FC<ServiceAlertHeaderProps> = ({
    alertType,
    placeHolder,
    alertDescription,
    alertDescription2,
    onCloseHandler,
    closeFlag,
    children,
    searchText,
    handleSearch,
    isFiltered,
    filter,
    onKeyDown,
    isBadgeEnabled = false,
    isFilterIcon = true,
    isDownloadIcon = true,
    isCreateAdvisory = false,
    handleOnClickDownload,
    setMakeAPICall,
}) => {
    return (
        <div className='filterprop'>
            <div>
                <TopHeader
                    children={
                        <ServiceAlertDropDownPage alertType={alertType} />
                    }></TopHeader>{' '}
            </div>
            <div style={{ marginTop: '-8px' }}>
                <Filter
                    alertType={alertType}
                    isDownloadIcon={isDownloadIcon}
                    isFilterIcon={isFilterIcon}
                    placeHolder={placeHolder}
                    searchOption1={alertDescription}
                    searchOption2={alertDescription2}
                    handleOnClickDownload={handleOnClickDownload}
                    onClose={onCloseHandler}
                    closeFlag={closeFlag}
                    children={children}
                    searchText={searchText}
                    customHeight={550}
                    handleSearch={handleSearch}
                    onKeyDown={onKeyDown}
                    isBadgeEnabled={isBadgeEnabled}
                    isCreateAdvisory={isCreateAdvisory}
                    setMakeAPICall={setMakeAPICall}
                />
            </div>
        </div>
    );
};

export default ServiceAlertHeader;
