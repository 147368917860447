/** @format */

import { useState, useEffect } from 'react';

//Apply CSS
import './Pagination.scss';

import { illusName, renderSvg } from '../../assets/img/imageExt';

type PaginationProps = {
    postsTitle: string;
    postsPerPage?: number;
    posts: any[];
    totalCountAvailable?: number;
    onIndex: (currentPosts: any[], noOfPages: number) => void;
    onIndexPageNo: (pageNo: number, postsPerPage: number) => void;
    selectPageOne: boolean;
    isBackClicked?: boolean;
    farmerCurrentPage?: number;
};
const Pagination = ({
    postsTitle,
    postsPerPage = 10,
    posts,
    totalCountAvailable = 10,
    onIndex,
    onIndexPageNo,
    selectPageOne,
    isBackClicked = false,
    farmerCurrentPage,
}: PaginationProps) => {
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [postsBeingDisplayed, setPostsBeingDisplayed] = useState<number>();
    const [onlyPageClicked, setOnlyPageClicked] = useState<boolean>(false);
    const [isFarmerBackClicked, setIsFarmerBackClicked] = useState<boolean>(
        isBackClicked ? true : false
    );

    // 

    let totalPosts = posts.length;
    const noOfPages = Math.ceil(totalCountAvailable / postsPerPage);
    // 
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    let pageNo: number;

    useEffect(() => {
        isFarmerBackClicked && setCurrentPage(farmerCurrentPage);
        setIsFarmerBackClicked(false);

        selectPageOne && setCurrentPage(1);
    }, [currentPage, posts]);

    const pagesArray = [];
    for (let i = 1; i <= noOfPages; i++) {
        pagesArray.push(i);
    }

    const previousPage = () => {
        setCurrentPage((current: any) => {
            onIndexPageNo(current - 1, postsPerPage);
            pageNo = current - 1;
            return current - 1;
        });
        setOnlyPageClicked(true);
    };

    const nextPage = () => {
        setCurrentPage((current: any) => {
            onIndexPageNo(current + 1, postsPerPage);
            pageNo = current + 1;
            return current + 1;
        });
        setOnlyPageClicked(true);
    };

    const handlePageNoChange = (value: number | any) => {
        setCurrentPage(value);
        pageNo = value;

        onIndexPageNo(value, postsPerPage);
        setOnlyPageClicked(true);
    };

    let pageArray = [];
    let i = 1;
    while (i <= noOfPages) {
        if (
            i <= 1 ||
            i >= noOfPages ||
            (i >= currentPage - 1 && i <= currentPage + 1)
        ) {
            pageArray.push(i);
            i++;
        } else {
            pageArray.push('...');
            i = i < currentPage ? currentPage - 1 : noOfPages;
        }
    }
    // 
    // 

    const indexes: any = [];
    for (let i = 0; i < pageArray.length; i++) {
        if (pageArray[i] === '...') {
            indexes.push(i);
        }
    }
    return (
        <div className='containerPagination'>
            <div className='pagesContainerPagination'>
                <label className='basicTextPagination'>{'Displaying'}</label>
                <span id='itemsCountPagination'>{posts.length}</span>
                <label className='basicTextPagination'>{'out of'}</label>
                <span className='totalCountContainer'>
                    {totalCountAvailable}
                </span>
                <span className='postsTitle'>{postsTitle}</span>
            </div>
            <div className='pagesContainerPagination'>
                {renderSvg(
                    currentPage === 1
                        ? illusName.boxBackDisabled
                        : illusName.boxBackEnabled,
                    '40px',
                    currentPage === 1 ? null : previousPage
                )}

                {pageArray.map((pageNo, index) =>
                    indexes.includes(index) ? (
                        <span key={pageNo}>{pageNo}</span>
                    ) : (
                        <span
                            key={pageNo}
                            onClick={() => {
                                handlePageNoChange(pageNo);
                            }}
                            className={`pageNoContainerPagination ${
                                currentPage === pageNo && 'activePagination'
                            }`}>
                            {' '}
                            {pageNo}
                        </span>
                    )
                )}
                {renderSvg(
                    currentPage === noOfPages
                        ? illusName.boxNextDisabled
                        : illusName.boxNextEnabled,
                    '40px',
                    currentPage === noOfPages ? null : nextPage
                )}
            </div>
        </div>
    );
};

export default Pagination;
