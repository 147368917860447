import "../../assets/scss/layout.scss";

type FooterProps= {
    children?: React.ReactNode
}

const Footer = (props: FooterProps) => {
	return (
		<footer>
            {props.children}
		</footer>
	);
};
export default Footer;
