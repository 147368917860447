/** @format */

import { Fragment, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface Props {
    // user: Object;
    user: any;
}
/**
 *Crop Yield Form Functional Component
 * @param props
 * @returns
 */
const CropYield: React.FC<Props> = ({ user }) => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleResize);
    }, []);
    const [innerWidth, innerHeight] = size;
    const labels = user.cropsgrown.map((cropArray: any) => cropArray.cropname);
    const prevSeasonYield: any = user.cropsgrown.map(
        (cropObj: any) => cropObj.previousseasonyield
    );
    const currentSeasonYield: any = user.cropsgrown.map(
        (cropObj: any) => cropObj.currentseasonyield
    );

    const options: any = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position:
                    innerWidth > 1000 ? ('right' as const) : ('top' as const),
                // position: 'top' as const,
                // align: 'center' as const,
                align:
                    innerWidth > 1000 ? ('start' as const) : ('end' as const),
                labels: {
                    padding: 10,
                    usePointStyle: true,

                    font: {
                        size:
                            innerWidth > 1000 ? 10 : innerWidth > 300 ? 12 : 8,

                        // family: 'appRegular',
                        family: 'appMedium',
                    },
                },
            },
            title: {
                display: false,
                text: '',
            },
        },
        scales: {
            xAxes: {
                grid: {
                    display: false,
                },
            },

            y: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'In Tones/Acre',
                },
            },
        },
    };

    const data = {
        labels,
        datasets: [
            {
                label: 'Prev. Season',
                data: prevSeasonYield,
                backgroundColor: '#4791FF',
                categoryPercentage: 0.2,
                barPercentage: 0.9,
            },
            {
                label: 'Current Season',
                data: currentSeasonYield,
                backgroundColor: '#FFD950',
                categoryPercentage: 0.2,
                barPercentage: 0.9,
            },
        ],
    };
    return (
        <Fragment>
            <div style={{ height: '100%' }}>
                <Bar options={options} data={data} />
            </div>
        </Fragment>
    );
};

export default CropYield;
