/** @format */

const INITIAL_STATE: any = {
    locationHierarchy: [
        // {
        //     locationLevel: 0,
        //     locationHierarchyName: '',
        //     parentLocationName: '',
        // },
    ],
    loadEffect: true
};

const locationHierarchyReducer = (state = INITIAL_STATE, action: any): any => {
    switch (action.type) {
        case 'ADD_LOCATION_HIERARCHY_ITEM_ROW': {
            return {
                locationHierarchy: [
                    ...state.locationHierarchy,
                    {
                        locationLevel: state.locationHierarchy.length,
                        locationHierarchyName: '',
                        parentLocationName: '',
                    },
                ],
                loadEffect: false
            };
        }
        case 'REMOVE_LOCATION_HIERARCHY_ITEM': {
            const updatedLocationHierarchyList = state.locationHierarchy.filter(
                (locationHierarchy: any) =>
                    locationHierarchy.locationLevel !==
                    action.payload.locationLevel
            );

            const updatedLocationHierarchyListIndex =
                updatedLocationHierarchyList.map(
                    (locationHierarchy: any, index: number) => {
                        return {
                            locationLevel: index,
                            locationHierarchyName:
                                locationHierarchy.locationHierarchyName,
                            parentLocationName:
                                locationHierarchy.parentLocationName,
                        };
                    }
                );

            return {
                locationHierarchy: updatedLocationHierarchyListIndex,
                loadEffect: false
            };
        }

        case 'UPDATE_LOCATION_HIERARCHY_LIST': {
            
            const updatedLocationHierarchyList = state.locationHierarchy.map(
                (locationHierarchy: any) => {
                    let newLocationHierarchy = { ...locationHierarchy };
                    if (
                        locationHierarchy.locationLevel ===
                        action.payload.item.locationLevel
                    ) {
                        newLocationHierarchy[action.payload.eventName] =
                            action.payload.item[action.payload.eventName];
                    }
                    
                    return newLocationHierarchy;
                }
            );
            return {
                locationHierarchy: [...updatedLocationHierarchyList],
                loadEffect: false
            };
        }

        case 'RESET_LOCATION_HIERARCHY_LIST': {
            
            return {
                locationHierarchy: [
                    {
                        locationLevel: 0,
                        locationHierarchyName: '',
                        parentLocationName: '',
                    },
                ],
                loadEffect: false
            };
        }
        case 'UPDATE_LOCATION_HIERARCHY_ROWS' : {
            const {data, bool} = action.payload
            
            let temparr:any = []
            data.forEach((row:any)=>{
                temparr.push(
                    // ...state.locationHierarchy,
                    {
                        locationLevel: temparr.length,
                        locationHierarchyName: '',
                        parentLocationName: '',
                    },
                )
                
            })
            
            return {
                locationHierarchy : temparr,
                loadEffect : bool
            }
        }
        case 'UPDATE_LOCATION_HIERARCHY_DEFAULT' : {

            return {
                locationHierarchy: [
                    {
                        locationLevel: 0,
                        locationHierarchyName: '',
                        parentLocationName: '',
                    },
                ],
                loadEffect: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default locationHierarchyReducer;
