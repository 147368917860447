// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/HelveticaNeue-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/HelveticaNeue-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/HelveticaNeue-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/HelveticaNeue-MediumItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/HelveticaNeue-BoldItalic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/HelveticaNeue-Italic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/** @format */\n@font-face {\n  font-family: \"appRegular\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"appBold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"appMedium\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"appMediumItalic\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"appBoldItalic\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");\n}\n@font-face {\n  font-family: \"appItalic\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");\n}", "",{"version":3,"sources":["webpack://./src/assets/scss/fonts.scss"],"names":[],"mappings":"AAAA,aAAA;AAEA;EACI,yBAAA;EACA,+DAAA;AAAJ;AAEA;EACI,sBAAA;EACA,+DAAA;AAAJ;AAEA;EACI,wBAAA;EACA,+DAAA;AAAJ;AAEA;EACI,8BAAA;EACA,+DAAA;AAAJ;AAEA;EACI,4BAAA;EACA,+DAAA;AAAJ;AAEA;EACI,wBAAA;EACA,+DAAA;AAAJ","sourcesContent":["/** @format */\n\n@font-face {\n    font-family: 'appRegular';\n    src: url('../fonts/HelveticaNeue-Regular.ttf') format('truetype');\n}\n@font-face {\n    font-family: 'appBold';\n    src: url('../fonts/HelveticaNeue-Bold.ttf') format('truetype');\n}\n@font-face {\n    font-family: 'appMedium';\n    src: url('../fonts/HelveticaNeue-Medium.ttf') format('truetype');\n}\n@font-face {\n    font-family: 'appMediumItalic';\n    src: url('../fonts/HelveticaNeue-MediumItalic.ttf') format('truetype');\n}\n@font-face {\n    font-family: 'appBoldItalic';\n    src: url('../fonts/HelveticaNeue-BoldItalic.ttf') format('truetype');\n}\n@font-face {\n    font-family: 'appItalic';\n    src: url('../fonts/HelveticaNeue-Italic.ttf') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
