/** @format */

import React, { useEffect, useState } from 'react';
import './Stepper.scss';
import { renderSvg, svgIconName } from '../../../assets/img/imageExt';

const StepperComponent = (Props: any) => {
    const [stepstepper, setStepstepper] = useState([]);

    useEffect(() => {
        stepsInfo();
    }, [Props.steps, Props.currentStepNumber]);

    const stepsInfo = () => {
        const { steps, currentStepNumber } = Props;
        const stepsState = steps.map((step: any, index: any) => {
            const stepObj: any = {};
            stepObj.description = step;
            stepObj.highlighted = index === 0 ? true : false;
            stepObj.selected = index === 0 ? true : false;
            stepObj.completed = false;
            return stepObj;
        });

        const currentSteps: any = updateStep(currentStepNumber, stepsState);
        setStepstepper(currentSteps);
    };

    const updateStep = (stepNumber: any, steps: any) => {
        const newSteps = [...steps];
        let stepCounter = 0;

        while (stepCounter < newSteps.length) {
            // Current step
            if (stepCounter === stepNumber) {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: true,
                    selected: true,
                    completed: false,
                };
                stepCounter++;
            }
            // Past step
            else if (stepCounter < stepNumber) {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: true,
                    selected: true,
                    completed: true,
                };
                stepCounter++;
            }
            // Future step
            else {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: false,
                    selected: false,
                    completed: false,
                };
                stepCounter++;
            }
        }
        return newSteps;
    };

    const { direction, stepColor } = Props;

    const stepsJSX = stepstepper.map((step: any, index: any) => {
        return (
            <div className='step-wrapper' key={index}>
                <div
                    style={{ paddingBottom: '17px' }}
                    className={`step-description ${
                        step.highlighted
                            ? 'step-description-active'
                            : 'step-description-inactive'
                    }`}>
                    {step.description}
                </div>
                <div
                    className={`step-number ${
                        step.selected
                            ? 'step-number-selected'
                            : 'step-number-disabled'
                    }`}
                    style={{
                        background: `${step.selected ? stepColor : 'none'}`,
                    }}>
                    {step.completed ? (
                        <span className='tick_icon'>
                            {renderSvg(svgIconName.tick_icon, 20)}
                        </span>
                    ) : (
                        index + 1
                    )}
                </div>
                {index !== stepstepper.length - 1 && (
                    <div
                        className={`divider-line divider-line-${
                            stepstepper.length
                        } ${step.completed && 'completed-stage'}`}
                    />
                )}
            </div>
        );
    });

    return (
        <div className={`stepper-wrapper-${direction}`}>
            <>
                <span style={{ display: 'grid' }}>
                    <span className='step-number_initial'></span>
                    <span className='divider_line_initial'></span>
                </span>
                {stepsJSX}
            </>
        </div>
    );
};

export default StepperComponent;
