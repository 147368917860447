/** @format */

import { Navigate } from 'react-router-dom';
import Authorization from './authorization';

function PublicRoute({ children }: any) {
    const isLoggedIn = Authorization.isLoggedIn();
    if (isLoggedIn) {
        // logged in so redirect to dashboard with the return url
        return <Navigate to='/dashboard' />;
    }

    // authorized so return child components
    return children;
}

export { PublicRoute };
