/** @format */

import { Fragment } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './Header';
import Box from '@mui/material/Box';
import Authorization from '../../utils/authorization';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
export const withRouter = (Component: any) => {
    const Wrapper = (props: any) => {
        const history = useNavigate();
        return <Component history={history} {...props} />;
    };
    return Wrapper;
};


const Layout = (props: any) => {
    const isLoggedIn = Authorization.isLoggedIn();
    return isLoggedIn ? (
        <Fragment>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Header />
                <Box
                    component='main'
                    sx={{
                        // backgroundColor: (theme) =>
                        //   theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
                        backgroundColor: '#ffffff',
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}>
                    <Toolbar />
                    <Grid sx={{ pt: 1, pb: 1, pl: 12, pr: 4 }}>
                        {props.children}
                    </Grid>
                </Box>
                <Footer />
            </Box>
        </Fragment>
    ) : (
        <Fragment>
            <CssBaseline />
            {props.children}
        </Fragment>
    );
};
// export default Layout;
export default withRouter(Layout);
