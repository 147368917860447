/** @format */

import { Button, Checkbox, Grid } from '@mui/material';
import React, { useState } from 'react';
import { renderSvg, svgIconName } from '../../../assets/img/imageExt';
import './ConsentForm.scss';
import '../land-crop-info/LandCropInfo.scss';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
    updateDataPrivacyConsent,
    updateDataConsent,
    resetConsentForm,
} from '../../../redux/actions/createUser/create';
import { connect } from 'react-redux';

const ConsentForm = (props: any) => {
    const [double, setDouble] = useState(false);

    const handlePageBack = () => {
        props.goBackOnePage(2);
    };

    const handlePageNext = () => {
        props.handleSendConsent();
        setDouble(true);
    };

    const onButtonClick = () => {
        fetch('privacy_document.pdf').then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'SamplePDF.pdf';
                alink.click();
            });
        });
    };

    return (
        <>
            <Grid container spacing={2} className='consent_topdiv'>
                <Grid item xs={6} className='consent_leftdiv'>
                    <div>
                        <div>
                            <div className='consent_header'>Consent Form</div>
                            <div className='consent_body_top'>
                                Getting consent from Farmers to store their data
                                in our system.
                            </div>
                        </div>
                        <div style={{ paddingTop: '60px' }}>
                            <div className='consent-checkbox-create'>
                                <Checkbox
                                    checked={props.dataPrivacyConsent}
                                    onChange={(e) =>
                                        props.updateDataPrivacyConsent(
                                            e.target.checked
                                        )
                                    }></Checkbox>
                                <div className='consent_subheader'>
                                    Data Privacy Consent
                                </div>
                            </div>
                            <div className='consent_subBody'>
                                By signing, you agree to Monsanto Holdings
                                Private Limited, Bayer CropScience, and Bayer
                                BioScience, and their affiliates to use your
                                personal information to receive notifications on
                                irrigation schedules, rain alerts, spray alerts,
                                pest control recommendations, disease alerts,
                                weather alerts and advisories, and other
                                customized advisories and alerts.
                            </div>
                        </div>
                        <div style={{ paddingTop: '15px' }}>
                            <div className='consent-checkbox-create'>
                                <Checkbox
                                    checked={props.dataConsent}
                                    onChange={(e) =>
                                        props.updateDataConsent(
                                            e.target.checked
                                        )
                                    }></Checkbox>
                                <div className='consent_subheader'>
                                    Marketing Consent
                                    <span className='consent_subheader_optional'>
                                        (Optional)
                                    </span>
                                </div>
                            </div>
                            <div className='consent_subBody'>
                                I consent to share my personal data for
                                marketing/promotion campaign and allow Bayer
                                West-Central Africa including its affiliates,
                                located outside the country, to use my personal
                                information in accordance with various Marketing
                                Consent.
                            </div>
                        </div>
                        <div className='consent_footer_message'>
                            By creating new farmer, a notification is sent to
                            the respective farmer where the system will request
                            for Data Privacy Consent and Marketing Consent
                            (optional) from the farmer.
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6} className='consent_leftdiv'>
                    <div className='consent_right_grid'>
                        <div className='downloadicon-createframer'>
                            <div className='consent_right_header'>
                                PRIVACY DOCUMENT
                            </div>

                            <div style={{ cursor: 'pointer' }}>
                                <FileDownloadOutlinedIcon
                                    onClick={
                                        onButtonClick
                                    }></FileDownloadOutlinedIcon>
                            </div>
                        </div>
                        <div className='consent_right_scrolldiv'>
                            <div className='consent_right_body_top'>
                                This application "Gravity" is provided by
                                Monsanto Holdings Private Limited (hereinafter
                                „us“ or „we“). For further information regarding
                                the provider of the App, please refer to our
                                imprint Cognizant, Cognizant Corporated Office,
                                Menon Eternity 165, St. Mary’s Road, Alwarpet,
                                Chennai 600 018 Tamil Nadu,
                            </div>
                            <div style={{ paddingTop: '12px' }}>
                                <div className='consent_right_subheader'>
                                    Handling of personal data
                                </div>
                                <div className='consent_right_subbody'>
                                    In the following we wish to provide you with
                                    information on how we handle your personal
                                    data when you use our App. Unless otherwise
                                    indicated in the following chapters, the
                                    legal basis for the handling of your
                                    personal data results from the fact that
                                    such handling is required to make available
                                    the functionalities of the App as requested
                                    by you (Art. 6(1)(b) General Data Protection
                                    Regulation).
                                </div>
                            </div>
                            <div style={{ paddingTop: '12px' }}>
                                <div className='consent_right_subheader'>
                                    Using our App
                                </div>
                                <div className='consent_right_subheader_text'>
                                    Accessing our App
                                </div>
                                <div className='consent_right_subbody_text'>
                                    In order to make available to you the
                                    services of our App, it will transfer the
                                    following information to us, every time you
                                    use our App: <br />
                                    <div style={{ paddingLeft: '30px' }}>
                                        <li>IP address</li>
                                        <li>Date and time of access</li>
                                        <li>
                                            Time zone difference to Greenwich
                                            Mean Time (GMT)
                                        </li>
                                        <li>Operating system information</li>
                                        <li>
                                            Status of access/HTTP status code
                                        </li>
                                        <li>Transferred volume of data</li>
                                        <li>
                                            Log-in credentials (username and
                                            password){' '}
                                        </li>
                                    </div>
                                </div>
                                <div
                                    className='consent_right_subbody_text'
                                    style={{ paddingTop: '15px' }}>
                                    To protect our legitimate interests, we will
                                    store such information for a limited period
                                    of time in order to be able to initiate a
                                    tracking of personal data in the event of
                                    actual or attempted unauthorized access to
                                    our servers (Art. 6(1)(f) General Data
                                    Protection Regulation).
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div className='threeButtonsContainer'>
                <span style={{ display: 'flex' }}>
                    <span className='role_def_back_icon'>
                        {renderSvg(svgIconName.down_arrow, 10)}
                    </span>
                    <a onClick={handlePageBack} className='roleDef_goBack_text'>
                        Go back
                    </a>
                </span>
                <span className='threeButtonsReset'>
                    <Button
                        style={{
                            width: '181px',
                            height: '35px',
                        }}
                        disabled
                        className='roleDef_reset_btn'>
                        Reset
                    </Button>
                </span>
                <span>
                    <Button
                        onClick={handlePageNext}
                        disabled={double}
                        style={{
                            borderRadius: '20px',
                            backgroundColor: '#7eb343',
                            width: 'fitContent',
                            height: '35px',
                            paddingLeft: '20px',
                            paddingRight: '44px',
                            border: 0,
                            boxShadow: '0px 3px 6px #c7c7c729',
                        }}
                        className='roleDef_next_btn'>
                        Create Farmer
                    </Button>
                </span>
            </div>
        </>
    );
};

const mapStateToProps = ({
    createUser: { dataPrivacyConsent, dataConsent },
}: any) => {
    return {
        dataPrivacyConsent: dataPrivacyConsent,
        dataConsent: dataConsent,
    };
};

const mapDispatchToProps = {
    updateDataPrivacyConsent: (val: any) => updateDataPrivacyConsent(val),
    updateDataConsent: (val: any) => updateDataConsent(val),
    resetConsentForm: resetConsentForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsentForm);
