/** @format */

// Local storage
export const setLocalStorageData = (key: any, value: any) =>
    localStorage.setItem(key, value);
export const getLocalStorageData = (key: any) => localStorage.getItem(key);
export const clearLocalStorageData = (key: any) => localStorage.removeItem(key);

// Session storage
export const setSessionData = (key: any, value: any) =>
    sessionStorage.setItem(key, value);
export const getSessionData = (key: any) => sessionStorage.getItem(key);
export const clearSessionData = (key: any) => sessionStorage.removeItem(key);
