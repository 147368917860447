import CloseIcon from '@mui/icons-material/Close';
import CloseSharpIcon from '@mui/icons-material/CloseSharp'; 
import { Button } from '@mui/material';
import { useState } from 'react';
import './RejectPopup.scss';

const RejectPopup = ({closePopup,confirmReject}:any) => {
    const [comment,setComment] = useState<any>('')
    const handleChange = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value)
    }
    let charCount = comment.length

    const handleConfirmReject = () => {
        confirmReject(true,comment)
        closePopup(false)
    }

    return(
        <div className='rejectPopup_bg'>
            <div className='rejectPopup_container'>
                <div className='rejectPopup_header_div'>
                    <span className="rejectPopup_header">
                        Reject Reason
                    </span>
                    <span>
                        <CloseIcon
                            className='rejectPopup_close_icon'
                            onClick={()=>closePopup(false)}/>
                    </span>
                </div>
                <div>
                    <div className='rejectPopup_body_message'>
                        Are you sure you would like to reject this disease alert?
                    </div>
                    <form onSubmit={handleConfirmReject}>
                        <div>
                            <textarea 
                                placeholder='Please enter a valid reason...'
                                className='rejectPopup_comment_textarea'
                                required
                                maxLength={100}
                                onChange={handleChange}/>
                            <div className='rejectPopup_char_count'>character {charCount} / 100</div>
                        </div>
                        <div className='rejectPopup_footer_div'>
                            <Button type='submit' className='rejectPopup_btn' >
                                <CloseSharpIcon style={{fontSize:'small',marginRight:'3px'}} />
                                Reject
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RejectPopup