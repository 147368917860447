/** @format */

import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Drawer } from './Drawer';
import GravityLogo from '../../assets/img/logo/app_logo.svg';
import { DrawerList } from './DrawerList';
import authorization from '../../utils/authorization';
import _ from 'lodash';
import { Box } from '@mui/material';
import './Header.scss';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    //   zIndex: theme.zIndex.drawer ,
    zIndex: '0',
    boxShadow: 'none',
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    ...(open &&
        {
            //marginLeft: drawerWidth,
            // width: `calc(100% - ${drawerWidth}px)`,
            //backgroundColor:"#fffffF",
            // width: "100%",
            // transition: theme.transitions.create(["width", "margin"], {
            //   easing: theme.transitions.easing.sharp,
            //   duration: theme.transitions.duration.enteringScreen,
            // }),
        }),
}));


function HeaderContent() {
    const drawerWidth: number = 240;
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const [size, setSize] = React.useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    React.useEffect(() => {
        const handleResize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [innerWidth, innerHeight] = size;

    const userData = authorization.getAuthUser();

    return (
        <React.Fragment>
            {/* <AppBar position="absolute" open={open} color="inherit">
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1,color:"#10384F" }}
            >
              DASHBOARD
            </Typography>
            <div className="header-logo">
            <img src={BayerLogoImg} alt="Bayer Logo" height={60} />
            </div>
          </Toolbar>
        </AppBar> */}
            {userData.roleid !== 'DEV_ADMIN' &&
                // (window.innerWidth >= 600 ? (
                (innerWidth >= 600 ? (
                    <Drawer variant='permanent' open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}>
                            <div className='sidebar-logo'>
                                <img
                                    src={GravityLogo}
                                    height='50'
                                    alt='Gravity Logo'
                                />
                            </div>

                            <div
                                className='auth-details'
                                style={{
                                    display: `${
                                        !open ? 'none' : 'inline-block'
                                    }`,
                                }}>
                                <p className='name'>{`${userData.firstname} ${userData.lastname}`}</p>
                                <p className='role'>{`${
                                    userData.lineofbusiness
                                } - ${_.startCase(userData.rolename)}`}</p>
                            </div>

                            <IconButton onClick={toggleDrawer}>
                                {open ? (
                                    <ChevronLeftIcon />
                                ) : (
                                    <ChevronRightIcon
                                        sx={{ marginRight: '-15px' }}
                                    />
                                )}
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List
                            component='nav'
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                            }}>
                            <DrawerList open={open} />
                        </List>
                    </Drawer>
                ) : (
                    <React.Fragment>
                        <AppBar
                            style={{
                                backgroundColor: '#FFF',
                                color: 'inherit',
                            }}
                            sx={{
                                width: {
                                    sm: `calc(100% - ${drawerWidth}px)`,
                                },
                                ml: { sm: `${drawerWidth}px` },
                            }}
                            position='fixed'>
                            <Toolbar>
                                <IconButton
                                    color='inherit'
                                    aria-label='open drawer'
                                    edge='start'
                                    onClick={toggleDrawer}
                                    sx={{ mr: 2, display: { sm: 'none' } }}>
                                    <MenuIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>

                        <Box
                            component='nav'
                            sx={{
                                width: { sm: drawerWidth },

                                flexShrink: { sm: 0 },
                            }}
                            aria-label='mailbox folders'>
                            <Drawer
                                variant='temporary'
                                open={open}
                                onClose={toggleDrawer}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },

                                    '& .MuiDrawer-paper': {
                                        boxSizing: 'border-box',
                                        width: drawerWidth,
                                    },
                                }}>
                                <List
                                    component='nav'
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flex: 1,
                                    }}>
                                    <DrawerList open={open} />
                                </List>
                            </Drawer>
                        </Box>
                    </React.Fragment>
                ))}
        </React.Fragment>
    );
}

export default function Header() {
    return <HeaderContent />;
}
