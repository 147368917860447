/** @format */

export const calendarChange = (val: any) => {
    return {
        type: 'HANDLE_CALENDAR_CHANGE',
        payload: {
            value: val,
        },
    };
};

export const RESET_ACTION = {
    type: 'RESET',
};
