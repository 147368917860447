import React, { useEffect } from 'react';
import FarmerImg from '../../assets/img/svg/farmer-page-icons/farmer.svg';
import '../countryConfig/countryConfig/Stepper.scss';
import './cropStageWise.scss';
import { cropName, renderSvg, svgIconName } from '../../assets/img/imageExt';

const CropStageStepper = (stepperobj:any) => {

    return (
        <div className='cropdetail_stepper_topdiv'>
            <div className='step_wrapper_cropstage'>
                <span style={{ display: 'grid' }}>
                    <span className='step_number_initial_cropstage'></span> 
                    <span className='divider_line_initial_cropstage'></span>
                </span>
                {stepperobj.stepperobj && 
                    stepperobj.stepperobj.map((item:any, index:any) => (
                    <>
                    
                    <div className='step_cropstage' key={index}>
                        <div
                            className='step_description_cropstage'
                        >
                            {item.cropstageslabel}
                        </div>
                        <div className='step_icon_cropstage'>
                            <img 
                                src={FarmerImg}
                                alt='img'
                            />
                        </div>
                    </div>
                    <span className='divider_line_cropstages' />
                    </>
                ))}  
            </div>
            <div>
                <div className='stages_alert_div'>
                    <span className='stages_alert_header'>
                        Pests Alerts Management
                    </span>
                    {stepperobj.stepperobj 
                        && stepperobj.stepperobj.map((data:any, index:any)=> (
                        <span className='stages_alert_content_top'>
                            {data.pestalerts.length > 0 ?
                                data.pestalerts.map((pest:any)=> (
                                    <div className='stages_alert_content'>
                                        <div className='stages_alert_content_name'>
                                            {pest.diseasename}
                                        </div> 
                                        <div className='stages_alert_content_count'>
                                            {pest.count}
                                        </div>
                                    </div>
                                ))
                            :
                                <div className='stages_alert_content'>
                                    <div className='stages_alert_content_na'>
                                        Nil
                                    </div> 
                                </div>
                            } 
                        </span>
                    ))
                    }                            
                </div>
                <div className='stages_alert_div'>
                    <span className='stages_alert_header'>
                        Disease Alerts Management
                    </span>
                    {stepperobj.stepperobj 
                        && stepperobj.stepperobj.map((data:any, index:any)=> (
                    <span className='stages_alert_content_top'>
                        {data.diseasealerts.length > 0 ? 
                            data.diseasealerts.map((disease:any)=> (
                                <div className='stages_alert_content'>
                                    <div className='stages_alert_content_name'>
                                        {disease.diseasename}
                                    </div> 
                                    <div className='stages_alert_content_count'>
                                        {disease.count}
                                    </div>
                                </div>
                            ))
                        :
                            <div className='stages_alert_content'>
                                <div className='stages_alert_content_na'>
                                    Nil
                                </div> 
                            </div>
                        } 
                    </span> 
                    ))
                }                            
                </div>
            </div>
        </div>
    )
}

export default CropStageStepper;