/** @format */

export const basicInfoProvided = (
    infoName: string,
    value: string,
    lab: string = ''
) => ({
    type: 'BASIC_INFO_PROVIDED',
    payload: { infoName, value, label: lab },
});

export const resetBasicInfo = () => ({
    type: 'RESET_BASIC_INFO',
});

export const getCountryDetails = (countryCode:any,countryName:any,lob:any) => ({
    type: 'GET_COUNTRY_DETAILS',
    payload:{
        countryCode:countryCode,
        countryName:countryName,
        lob:lob
    }
});

export const locationLevels = (levelsArray: any[]) => ({
    type: 'LOCATION_LEVELS_PROVIDED',
    payload: levelsArray,
});

export const locationInputValuesProvided = (
    inputName: string,
    inputValue: string
) => ({
    type: 'LOCATION_INPUT_VALUES_PROVIDED',
    payload: { inputName, inputValue },
});

export const resetLocationInputs = () => ({
    type: 'RESET_LOCATION_INPUTS',
});

export const firstDataOrResetData = (obj: any) => ({
    type: 'FIRST_DATA_OR_RESET_DATA',
    payload: { dataType: obj.type, data: obj.data },
});

export const updateInfo = (val: any, lab: any, ind: any, name?: any) => ({
    payload: {
        label: lab,
        input: val,
        i: ind,
        name: name,
    },
    type: 'CREATE_USER_UPDATE_LAND_CROP_INFO',
});

export const addInfo = () => ({
    type: 'CREATE_USER_ADD_LAND_CROP_INFO',
});

export const removeInfo = (i: any, crop: any) => ({
    payload: {
        ind: i,
        crop: crop,
    },
    type: 'CREATE_USER_REMOVE_LAND_CROP_INFO',
});

export const resetInfo = () => ({
    type: 'CREATE_USER_RESET_LAND_CROP_INFO',
});

export const updateAcreage = (val: any) => ({
    payload: {
        acreValue: val,
    },
    type: 'UPDATE_ACREAGE',
});

export const updateDataPrivacyConsent = (val: any) => ({
    payload: {
        dataPrivacyConsent: val,
    },
    type: 'UPDATE_DATA_PRIVACY_CONSENT',
});

export const updateDataConsent = (val: any) => ({
    payload: {
        dataConsent: val,
    },
    type: 'UPDATE_DATA_CONSENT',
});


export const resetConsentForm = () => ({
    type: 'RESET_CONSENT_FORM',
});

export const RESET_ACTION = {
    type: 'RESET',
};