/** @format */

const INITIAL_STATE: any = {
    calendarValue: null,
};

const CalendarReducer = (state = INITIAL_STATE, action: any): any => {
    const { payload, type } = action;

    switch (type) {
        case 'HANDLE_CALENDAR_CHANGE': {
            return {
                ...state,
                calendarValue: payload.value,
            };
        }

        case 'RESET':
            return INITIAL_STATE;

        default:
            return state;
    }
};
export default CalendarReducer;
