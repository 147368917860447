/** @format */

import React from 'react';
import Select, { GroupBase } from 'react-select';

declare module 'react-select/dist/declarations/src/Select' {
    export interface Props<
        Option,
        IsMulti extends boolean,
        Group extends GroupBase<Option>
    > {
        label?: any;
        defaultValue?: any;
        handleChange?: any;
        handleInputChange?: any;
        isPlaceholder?: boolean;
        isLabel?: boolean;
        isNative?: boolean;
        id?: string;
        dataTestId?: string;
        width?: any;
        placeHolder?: string;
    }
}
export interface DropdownProps {
    label?: any;
    defaultValue?: any;
    options?: any;
    handleChange?: any;
    handleInputChange?: any;
    value: any;
    name: any;
    isPlaceholder?: boolean;
    isLabel?: boolean;
    isDisabled?: boolean;
    isNative?: boolean;
    id?: string;
    dataTestId?: string;
    width?: any;
    placeHolder?: string;
    isMulti?: boolean;
}


const customStyles = {
    control: (provided: any, { selectProps: { width } }: any) => ({
        ...provided,
        border: '1px solid #919191',
        width: width,
        fontSize: '13px',
        fontFamily: 'appRegular',

        // This line disable the blue border
        boxShadow: 'none',
        outline: 'none',
        '&:hover': {
            outline: 'none',
        },
    }),
    option: (provided: any) => ({
        ...provided,
        fontSize: '13px',
        fontFamily: 'appRegular',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: '#AAAAAA',
    }),

};

export default function ReactSelect({
    label,
    defaultValue,
    options,
    handleChange,
    handleInputChange,
    value,
    name,
    isPlaceholder,
    isLabel,
    isDisabled,
    isNative,
    id,
    dataTestId,
    width,
    placeHolder,
    ...props
}: DropdownProps) {
    const defaultOptions = [{ value: 'ALL', label: 'ALL' }];
    return (
        <div style={{width:'204px'}}>
            <Select
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={options}
                value={
                    options?.length > 0 &&
                    options.filter(function (option: any) {
                        return option.value === value;
                    })
                }
                placeholder={placeHolder ? placeHolder : label}
                isClearable={false}
                isSearchable
                name={name}
                defaultValue={defaultOptions && defaultOptions[0]}
                isDisabled={isDisabled}
                noOptionsMessage={() => 'No records found!'}
                width={width}
                styles={customStyles}
                maxMenuHeight={200}
            />
        </div>
    );
}
