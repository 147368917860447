/** @format */

import { Fragment } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CustomTooltip from './CustomTooltip';
import Typography from '@mui/material/Typography';
interface Props {
    name: string;
    value: string;
    label?: string;
    placeHolder?: any;
    onChange?: any;
    onKeyPress?: any;
    onKeyUp?: any;
    width?: string;
    disabled?: boolean;
    type?: string;
    id?: string;
    handleChange?: any;
    onKeyDown?: any;
    title?: any;
    subtitle1?: any;
    subtitle2?: any;
}
/**
 *SearchBar Functional Component
 * @param props
 * @returns
 */
const SearchBar: React.FC<Props> = ({
    value,
    placeHolder,
    label,
    type,
    id,
    onChange,
    name,
    onKeyDown,
    title,
    subtitle1,
    subtitle2 = '',
    ...props
}) => {
    return (
        <Paper
            component='form'
            sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 325,
                borderRadius: '100px',
                background: '#FAFAFA',
                boxShadow: 'inset 0px 3px 6px #d8d8d829',
                border: '0.3px solid #B9B9B9',
                height: '35px',
                fontFamily: 'appRegular',
                marginRight: '20px',
            }}>
            <IconButton sx={{ p: '10px' }} aria-label='search'>
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{
                    ml: 1,
                    flex: 1,
                    fontSize: '0.80rem',
                    fontFamily: 'appMedium',
                }}
                inputProps={{ 'aria-label': placeHolder }}
                id={id}
                type={type}
                placeholder={placeHolder}
                onChange={onChange}
                name={name}
                onKeyDown={onKeyDown}
                value={value}
                autoComplete='off'
            />
            <CustomTooltip
                title={
                    <Fragment>
                        <Typography sx={{ color: '#000000' }}>
                            Search Fields
                        </Typography>
                        <Typography variant='subtitle2' gutterBottom>
                            1 - {subtitle1}
                        </Typography>
                        <Typography variant='subtitle2' gutterBottom>
                            {subtitle2 === '' ? '' : '2 - ' + subtitle2}
                        </Typography>
                    </Fragment>
                }
                placement='right'
                open={true}
                isArrow={true}
                style={{
                    color: '#1A1A1A',
                    backgroundColor: '#FFFFFF',
                }}
                enterTouchDelay={0}>
                <IconButton
                    sx={{ p: '10px', color: '#FF9D00' }}
                    aria-label='tootlip-title'>
                    <HelpOutlineIcon />
                </IconButton>
            </CustomTooltip>
        </Paper>
    );
};

export default SearchBar;
