/** @format */

import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface IProps extends TooltipProps {
    cusstyle?: any;
    isArrow?: boolean;
    issideba?: string;
}
const BootstrapTooltip = styled(({ className, ...props }: IProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme, issideba, cusstyle }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#DEDEDE',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        ...cusstyle,
        boxShadow: '0px 6px 6px #3B3B3B24',

        ...(issideba && {
            fontSize: '0.85rem',
        }),
    },
}));
export default function CustomTooltip({
    title,
    placement,
    open,
    children,
    isArrow,
    issideba,
    style,
    enterTouchDelay,
    ...props
}: IProps) {
    return open ? (
        <BootstrapTooltip
            title={title}
            placement={placement}
            arrow={isArrow}
            issideba={issideba}
            cusstyle={style}
            enterTouchDelay={enterTouchDelay}>
            {children}
        </BootstrapTooltip>
    ) : (
        children
    );
}
