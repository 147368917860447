/** @format */

import { Fragment, useState, MouseEvent, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import './PopupFilter.scss';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1,
}));

interface PopupFilterProps {
    children?: any;
    onClose?: any;
    customHeight?: any;
    closeFlag?: any;
}
const PopupFilter = ({
    onClose,
    customHeight,
    children,
    closeFlag,
}: PopupFilterProps) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const handleClick = (event: MouseEvent<HTMLOrSVGScriptElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        closeFlag(true);
    };
    useEffect(() => {
        onClose && onClose(handleClose);
    }, [onClose]);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // 

    return (
        <Fragment>
            <Item
                onClick={(e: any) => handleClick(e)}
                sx={{ cursor: 'pointer' }}>
                <FilterListSharpIcon />
            </Item>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleClose}>
                <Item
                    // sx={{ maxWidth: 460, height: customHeight }}
                    sx={{ maxWidth: 500 }}
                    className='popupItem'>
                    <Stack
                        justifyContent='flex-end'
                        alignItems='flex-end'
                        sx={{ paddingRight: '2%' }}>
                        <FilterListSharpIcon
                            onClick={() => handleClose()}
                            sx={{ cursor: 'pointer' }}
                        />
                    </Stack>
                    <div 
                        className='popover-filter'
                        style={{
                            paddingBottom: '30px'
                        }}>
                        {children}
                    </div>
                </Item>
            </Popover>
        </Fragment>
    );
};

export default PopupFilter;
