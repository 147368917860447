/** @format */

import { configureStore } from '@reduxjs/toolkit';
import countryConfigReducer from '../reducers/countryConfig';
import locationHierarchyReducer from '../reducers/locationHierarchy';
import createUserReducer from '../reducers/createUser';
import CalendarReducer from '../reducers/calendar';
import createCropReducer from '../reducers/createCrop';

export const store = configureStore({
    
    reducer: {
        locationHierarchy: locationHierarchyReducer,
        countryConfig: countryConfigReducer,
        createUser: createUserReducer,
        calendar: CalendarReducer,
        crop: createCropReducer,
    },

});
