/** @format */

import { Navigate } from 'react-router-dom';
import Authorization from './authorization';

function PrivateRoute({ children }: any) {
    const isLoggedIn = Authorization.isLoggedIn();
    if (!isLoggedIn) {
        // not logged in so redirect to login page with the return url
        return <Navigate to='/' />;
    }

    // authorized so return child components
    return children;
}

export { PrivateRoute };
